.navbar{
    position: absolute;
    justify-content: space-between;
    display: flex;
    max-width: 100%;
    margin: 2rem 2rem;
    position: fixed;
    z-index: 10000;
}

.logo img{
    width: 5rem;
}

.nav-menu li a{
    text-decoration: none;
    color: var(--white);
}

.navbar-social a{
    color: var(--white);
    list-style-type: none;
}

.hamburger{
    background: rgba(0, 0, 0, 0.307);
    padding: 1.5rem;
    border-radius: 50%;
}

.navbar-social{
    max-width: 100%;
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-top: 10rem;
    right: 2rem;
    position: fixed;
    z-index: 99;
    font-size: 1.5rem;
}

.navbar-follow{
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8rem;
}

.navbar-divider{
    border-left: 3px solid white;
    height: 30px;
}

.top-icon img{
    width: 3rem;
    margin-top: 9rem;
}

.navbar-social a:hover{
    color: var(--themeColor);
}

.navbar-social h3:hover{
    color: var(--themeColor);
}

@media screen and (max-width: 3000px){
    .hamburger{
        display: block;
        z-index: 999;
        position: absolute;
        position: fixed;
        margin-top: 20%;
    }

    .nav-menu{
        position: fixed;
        left: -100%;
        top: 0rem;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 100%;
        z-index: 999;
        transition: .3s;
        align-items: center;
       
    }

    .nav-menu.active{
        left: 0;
    }

    .nav-items{
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 10rem;
    }
}