.testimonial{
    width: 100%;
    height: 215vh;
    background: var(--testimonialbg);
    position: relative;
    overflow: hidden;
}

.t-content{
    margin: 15rem 17rem;
}

.t-heading{
    color: var(--white);
    text-transform: uppercase;
    font-size: 1.5rem;
    max-width: 100%;
}

.t-divider{
    border: 2px solid var(--themeColor);
    width: 9rem;
    margin-left: 19rem;
    
}

.t-video{
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    gap: 8rem;
}

.tt-content{
    display: flex;
    margin: 8rem 17rem;
    gap: 3rem;
}

.t-quotes img{
    width: 7rem;
}

.t-title{
    color: var(--white);
    gap: 2rem;
}

.t-title>:nth-child(1){
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.t-title>:nth-child(2){
    color: var(--themeColor);
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.t-title h5{
    color: var(--deepPurple);
    cursor: pointer;
}

.arrow{
    margin-top: 8rem;
    justify-content: space-around;
    text-align: center;
    gap: 2rem;
    display: flex;
    cursor: pointer;
}

.view{
    margin-top: 8rem;
    text-align: center;
}

.view button{
    width: 10rem;
    font-size: 1rem;
    height: 3.3rem;
    box-shadow: 0 0 5px var(--themeColor);
}

@media screen and (max-width: 680px) {
    .testimonial{
        position: absolute;
        max-width: 100%;
        margin-top: -17rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 180vh;
    }

    .t-content{
        max-width: 100%;
        margin-top: -30rem;
    }

    .t-heading h2{
        font-size: 1rem;
    }

    .t-divider{
        position: absolute;
        border: .5px solid var(--themeColor);
        width: 3.8rem;
        margin-left: 2.5rem;
    }

    .t-video{
        flex-direction: column;
        position: absolute;
        margin: auto;
        margin-top: 2rem;
        margin-left: -5rem;
    }

    .tt-content{
        display: none;
    }

    .t-title{
        position: relative;
        display: flex;
    }

    .arrow{
        display: none;
    }

    .view{
        position: absolute;
        margin-top: 60rem;
    }

    .view button{
        height: 2rem;
        width: 6rem;
        font-size: .6rem;
        margin-left: 1rem;
    }

    .t-quotes img{
        width: 2rem;
    }

    .t-title>:nth-child(1){
        font-size: 0.6rem;
        width: 70%;
        max-height: 1px;
    }
}

@media screen and (max-width: 1440px) {
    .testimonial{
        height: 260vh;
    }

    .t-divider{
        margin-left: 42.4%;
    }

    .tt-content{
        max-width: 70%;
        position: absolute;
        margin: 5rem auto;
    }

    .t-quotes img{
        width: 6rem;
    }

    .arrow{
        margin-top: 20rem;
    }

    .view{
        margin-top: 6rem;
    }

    .t-video{
        gap: 5rem;
    }

}
/* 
@media screen and (max-width: 1600px) {
    .testimonial{
        height: 235vh;
        width: 100%;
    }

    .tt-content{
        max-width: 100%;
        align-items: center;
        justify-content: space-between;
        margin: 2rem 0;
    }

    .t-title{
        max-width: 100%;
        position: absolute;
    }

    .t-title p{
        max-width: 80%;
    }
} */