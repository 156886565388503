.footer{
    width: 100%;
    background-color: var(--footer);
    height: 75vh;
    padding: 10rem 17rem;
    margin-top: -0.1rem;
    position: absolute;
}

.footer-content h3{
    text-transform: uppercase;
}

.footer-divider{
    border: 1px solid var(--themeColor);
    opacity: 30%;
}

.footer-content{
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    color: var(--white);
}

.f-first-content{
    flex-direction: column;
    display: flex;
    gap: 2rem;
}

.f-first-content img{
    width: 5rem;
}

.content-divider1{
    border: 1px solid var(--themeColor);
    width: 13%;
}

.second-content{
    flex-direction: column;
    display: flex;
    gap: 2rem;
}

.second-content a{
    color: var(--white);
    text-decoration: none;
}

.second-content a:hover{
    color: var(--deepPurple);
    text-decoration: true;
}

.third-content{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.third-content button{
    cursor: pointer;
}

.bottom-divider-footer{
    border: 1px solid var(--themeColor);
    margin-top: 5rem;
    opacity: 30%;
}

.bottom-span{
    color: var(--white);
    margin-top: 2rem;
    text-transform: capitalize;
}

.content-divider2{
    border: 1px solid var(--themeColor);
    width: 13%;
}

@media screen and (max-width: 680px) {
    .footer{
        width: 100%;
        height: 100%;
    }

    .footer-divider{
        border: 1px solid var(--themeColor);
        width: 28rem;
        position: absolute;
        left: 0;
        margin: -2rem 1rem;
    }

    .footer-content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 1rem 1rem;
        max-width: 100%;
        margin-left: -10rem;
        gap: 4rem;
    }

    .f-first-content{
        margin-left: -4rem;
    }

    .f-first-content img{
        width: 3rem;
    }

    .f-first-content h3{
        font-size: .6rem;
    }

    .f-first-content span{
        font-size: .6rem;
    }

    .second-content h3{
        font-size: .6rem;
    }

    .second-content span, a{
        font-size: .6rem;
    }

    .content-divider1{
        border: 1px solid var(--themeColor);
        width: 2rem;
    }

    .third-content{
        display: flex;
        flex-direction: column;
        position: absolute;
        margin: 20rem 1rem;
        margin-left: -4rem;
    }

    .third-content h3{
        font-size: .6rem;
    }

    .third-content button{
        font-size: 0.5rem;
        margin-top: -1rem;
        height: 2rem;
    }

    .bottom-span{
        display: flex;
        max-width: 100%;
        position: absolute;
        margin-top: 4rem;
        margin-left: -15rem;
        font-size: .6rem;
    }

    .bottom-divider-footer{
        border: 1px solid var(--themeColor);
        width: 28rem;
        margin-left: -16rem;
        margin-top: 10rem;
    }
}

@media screen and (max-width: 1440px) {
    .footer{
        width: 100%;
        height: 100%;
    }

    .content-divider2{
        border: 1px solid var(--themeColor);
        width: 2rem;
    }
}