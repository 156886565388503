.btm-bg{
    margin-top: -40rem;
    z-index: -1;
}

.btm-bg img{
    max-width: 100%;
}

@media screen and (max-width: 680px) {

    .btm-bg{
        z-index: -1;
        position: absolute;
        height: 100%;
        margin-top: -14rem;
    }
    .btm-bg img{
        /* width: 27.5rem; */
        max-width: 100%;
    }
}