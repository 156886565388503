.whychooseus{
    width: 100%;
    height: 200vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--darkBlue);
    z-index: 1;
    margin-top: 58rem;
    overflow: hidden;
}

.why-us-container{
    margin: 5rem 17rem;
}

.why-heading h1{
    color: var(--white);
    font-size: 3rem;
    text-transform: uppercase;
}

.why-divider{
    border: 2px solid var(--themeColor);
    width: 6%;
    margin-left: 24.5%;
}

.why-images{
    margin-top: 10rem;
    gap: 9.6rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.why-images img{
    width: 6.5rem;
}

.sol img{
    height: 4rem;
    width: 4rem;
}

.nada img{
    width: 5rem;
}

.why-ideas{
    margin-top: 10rem;
    max-width: 100%;
}

.why-heading2{
    color: var(--white);
}

.why-heading2 h1{
    text-transform: uppercase;
    font-size: 3.5rem;
    width: 20%;
}

.why-heading2 span{
    color: var(--themeColor);
}

.why-heading2 p {
    margin-top: 2rem;
    width: 59%;
    letter-spacing: 1px;
}

.why-brainimg{
    position: absolute;
    margin-top: -15rem;
    margin-left: 8rem;
    z-index: -1;
}

.why-btn{
    text-align: center;
    margin-top: 8rem;
}

.why-btn button{
    cursor: pointer;
    width: auto;
    padding: 1rem;
    font-size: 1rem;
    box-shadow: 0 0 5px var(--themeColor);
}

@media screen and (max-width: 680px) {
    .whychooseus{
        display: flex;
        overflow: hidden;
        width: 100%;
        margin-top: 40rem;
    }

    .why-us-container{
        margin: 0;
    }

    .why-heading h1{
        font-size: 1rem;
        padding: 0 1rem;
    }

    .why-divider{
        border: 1px solid var(--themeColor);
        margin-left: 8rem;
        width: 1.6rem;
    }

    .why-images{
        gap: 3rem;
        padding: 0 1rem;
    }

    .why-images img{
        width: 1.8rem;
    }

    .sol img{
        width: 1rem;
        height: 1rem;
    }

    .why-ideas{
        padding: 0 1rem;
        
    }

    .why-heading2{
        width: 100%;
        z-index: 9999;
    }

    .why-heading2 h1{
        font-size: 1rem;
    }

    .why-heading2 p{
        max-width: 100%;
        font-size: .5rem;
    }

    .why-brainimg img{
        width: 5rem;
        margin-left: -4rem;
        margin-top: 9rem;
    }

    .why-btn{
        margin-left: -15rem;
    }

    .why-btn button{
        font-size: .5rem;
        width: auto;
        height: auto;
    }
}

@media screen and (max-width: 1440px) {
    .whychooseus{
        height: 250vh;
    }
    .why-heading{
        margin-top: -5rem;
    }

    .why-divider{
        margin-left: 21rem;
        width: 5rem;
    }

    .why-images{
        max-width: 100%;
        gap: 6rem;
    }

    .why-images img{
        width: 5rem;
    }

    .sol img{
        width: 4rem;
        height: 3rem;
    }

    .why-ideas{
        max-width: 100%;
    }

    .why-heading2{
        max-width: 100%;
    }

    .why-heading2 h1{
        width: 40%;
    }
}