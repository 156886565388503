.mountain{
    max-width: 100%;

    margin-top: 30rem;
    position: absolute;
}

.mountain img{
    max-width: 100%;
}

@media screen and (max-width: 1440px) {
    .mountain{
        margin-top: 25rem;
    }
}

/* @media screen and (max-width: 1600px){
    .mountain{
        margin-top: 24rem;
    }
} */
