.clouds{
    max-width: 100%;
    position: absolute;
    z-index: -1;
}

.left-cloud img{
    max-width: 100%;
}

.right-cloud img{
    width: 50rem;
    max-width: 100%;
}