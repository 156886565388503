.digitally{
    z-index: 9999;
    position: relative;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 180rem;
    background: var(--darkBlue);
}

/* .digitally-container{
    content: '';
    position: absolute;
    background: url('../../assets/btm_bg.png') center center/cover;
    width: 100%;
    height: 100%;
} */

.end{
    color: white;
    flex-direction: column;
    display: flex;
}

.end{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 5rem;
    margin-top: 15rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.end>:nth-child(1){
    letter-spacing: 25px;
    opacity: 30%;
    margin-left: 1rem;
}

.end>:nth-child(2){
    max-width: 70%;
    font-size: 2.4rem;
    opacity: 30%;
}

@media screen and (max-width: 680px) {
    .digitally{
        width: 100%;
        height: 100%;
        z-index: 1;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .digitally-container{
        margin-top: -40rem;
    }

    .map{
        margin-left: -12rem;
    }

    .map img{
        width: 10rem;
    }

    .end>:nth-child(1){
        font-size: 2rem;
    }

    .end>:nth-child(2){
        font-size: 1.44rem;
        max-width: 100%;
        margin-left: -0.5rem;
    }

    .end{
        margin-left: -5rem;
        
    }

    .end>:nth-child(1){
        font-size: .8rem;
        margin-top: -10rem;
    }

    .end>:nth-child(2){
        font-size: 1.08rem;
    }
}

@media screen and (max-width: 1440px) {
    .end{
        margin-bottom: 10rem;
    }
}