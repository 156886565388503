.technology{
    width: 100%;
    height: 100vh;
    background: var(--darkBlue);
    position: absolute;
}

.technology-container{
    margin: 5rem 17rem;
}

.t-heading h1{
    color: var(--white);
    text-transform: uppercase;
    font-size: 3rem;
}

.t-heading .t1-divider{
    border: 2px solid var(--themeColor);
    width: 5rem;
    margin-left: 26.5rem;
}

.t-images{
    margin-top: 6rem;
    max-width: 100%;
    display: flex;
    gap: 8.5rem;
}

.t-images h1{
    font-size: 2rem;
}

.t-images img{
    width: 10rem;
    max-width: 100%;
}

.bottom-images{
    display: flex;
    justify-content: center;
    gap: 8rem;
    text-align: center;
}

.bottom-images img{
    width: 10rem;
    margin-top: 5rem;
    max-width: 100%;
}

.t-images, .bottom-images h1{
    color: var(--white);
    text-align: center;
    font-size: 2rem;
}

@media screen and (max-width: 680px) {
    .technology{
        width: 100%;
        display: flex;
        position: absolute;
    }

    .technology-container{
        margin: -20rem 1rem;
    }

    .t-heading h1{
        font-size: 0.8rem;
    }

    .t-divider{
        width: 5.3rem;
        border: 1px solid var(--themeColor);
    }
    
    .t-images{
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 1rem;
        
    } 

    .t-images img{
        width: 5rem;
        height: 5rem;
    }

    .t-images h1{
        font-size: 1rem;
    }

    .bottom-images{
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-top: -4rem;
        margin-left: -5.5rem;
    } 

    .bottom-images img{
        width: 5rem;
        height: 5rem;
    }

    .bottom-images h1{
        font-size: 1rem;
    }

    .reactjs{
        margin-top: -4rem;
    }

    .reactnative{
        margin-top: -4rem;
    }
}

@media screen and (max-width: 1440px) {

    .technology{
        width: 100%;
    }
    .t-divider{
        display: none;
        width: 2rem;
        border: 1px solid var(--themeColor);
    }

    .t-mobile{
        width: 100%;
        position: absolute;
    }

    .t-images h1{
        font-size: 1.5rem;
    }

    .t-images img{
        width: 5rem;
    }

    .bottom-images{
        margin: 0 13rem;
        position: absolute;
    }

    .bottom-images img{
        width: 5rem;
    }

    .bottom-images h1{
        font-size: 1.5rem;
    }

    .reactjs{
        margin-left: 1rem;
    }

    .reactnative{
        margin-left: -1.5rem;
    }

    .linus{
        margin-left: .2rem;
    }
}



